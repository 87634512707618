// Looking for the usual index.tsx content? Check in main.ts
//
// We split this out so that we have an async breakpoint as the entrypoint to
// the library, which allows us to perform "normal" imports of WASM code,
// which would otherwise require dynamic async imports.

// This is our async breakpoint. One async import at the top of the project
// lets us do normal imports for all other async imports.
 
import("./main").catch((e) => console.error(`failed to load content: ${e}`));

// React requires building TS with --isolated-modules, so everything has to have
// an import or an export statement. This export statement allows this file
// to compile.
export {};
